<template>
  <div class="home-page-wrapper">
    <top-header v-if="!userDataShowTop" @restoreDefault="goShouYe"  :headerShow="headerShow"  :userData="userData" @handleCommand="handleCommand"  @dialogVisibleShow="login"></top-header>

      <div class="product-center-wrapper">
        <div class="product-top-wrapper"></div>
        <div class="product-content-wrapper">
          <div class="product-content">
            <div class="product-type-wrapper">
              <el-tabs v-model="activeName" stretch>
                <el-tab-pane label="全部" name="first">
                  <div>
                    <div class="common-style">
                      <div v-for="(item, index) in basicList">
                        <img v-if="index == 0" src="../../assets/xinjiangImage/credit-report-basic-img.png" alt="">
                        <img v-if="index == 1" src="../../assets/xinjiangImage/credit-report-value-img.png" alt="">
                        <img v-if="index == 2" src="../../assets/xinjiangImage/company-relation-img.png" alt="">
                        <img v-if="index == 3" src="../../assets/xinjiangImage/product-img.png" alt="">
                        <img v-if="index == 4" src="../../assets/xinjiangImage/black-name-list-img.png" alt="">
                        <div class="solution-text">
                          <p>{{ item.productName }}</p>
                          <div class="product-detail" v-html="showHtml(item.productDesc)"></div>
                          <div class="product-operate-btn">
                            <img @click="collect(item, index)" v-if="item.userCollectstatus == 2 || item.userCollectstatus == null" src="../../assets/xinjiangImage/shouCangHover.png" alt="" />
                            <img @click="collect(item, index)" v-if="item.userCollectstatus == 1" src="../../assets/xinjiangImage/shouCangfocus.png" alt="" />
                            <img @click="subscribe(item, index)" v-if="item.userProductStatus == 2 || item.userProductStatus == null" src="../../assets/xinjiangImage/focus_hover.png" alt="" />
                            <img @click="subscribe(item, index)" v-if="item.userProductStatus == 1" src="../../assets/xinjiangImage/focus.png" alt="" />
                            <el-button round type="primary" size="mini" @click="goDetail(item)">了解详情</el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <el-divider v-if="basicList.length>0"></el-divider>

                    <div class="common-style">
                      <div v-for="(item, index) in solveList">
                        <img v-if="index == 0" src="../../assets/xinjiangImage/risk-monitor-img.png" alt="">
                        <img v-if="index == 1" src="../../assets/xinjiangImage/finance-img.png" alt="">
                        <img v-if="index == 2" src="../../assets/xinjiangImage/shopping-result-img.png" alt="">
                        <img v-if="index == 3" src="../../assets/xinjiangImage/intelligence-friend-img.png" alt="">
                        <div class="solution-text">
                          <p>{{ item.productName }}</p>
                          <div class="product-detail" v-html="showHtml(item.productDesc)"></div>
                          <div class="product-operate-btn">
                            <img @click="collect(item, index)" v-if="item.userCollectstatus == 2 || item.userCollectstatus == null" src="../../assets/xinjiangImage/shouCangHover.png" alt="" />
                            <img @click="collect(item, index)" v-if="item.userCollectstatus == 1" src="../../assets/xinjiangImage/shouCangfocus.png" alt="" />
                            <img @click="subscribe(item, index)" v-if="item.userProductStatus == 2 || item.userProductStatus == null" src="../../assets/xinjiangImage/focus_hover.png" alt="" />
                            <img @click="subscribe(item, index)" v-if="item.userProductStatus == 1" src="../../assets/xinjiangImage/focus.png" alt="" />
                            <el-button round type="primary" size="mini" @click="goDetail(item)">了解详情</el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <el-divider v-if="solveList.length>0"></el-divider>
                    <div class="common-style">
                      <div v-for="(item, index) in informationList">
                        <img v-if="index == 0" src="../../assets/xinjiangImage/agricultrue.png" alt="">
                        <img v-if="index == 1" src="../../assets/xinjiangImage/company-finance-platform-img.png" alt="">
                        <img v-if="index == 2" src="../../assets/xinjiangImage/local-finance-platform-img.png" alt="">
                        <img v-if="index == 3" src="../../assets/xinjiangImage/online-security-platform-img.png" alt="">
                        <div class="solution-text">
                          <p>{{ item.productName }}</p>
                          <div class="product-detail" v-html="showHtml(item.productDesc)"></div>
                          <div class="product-operate-btn">
                            <img @click="collect(item, index)" v-if="item.userCollectstatus == 2 || item.userCollectstatus == null" src="../../assets/xinjiangImage/shouCangHover.png" alt="" />
                            <img @click="collect(item, index)" v-if="item.userCollectstatus == 1" src="../../assets/xinjiangImage/shouCangfocus.png" alt="" />
                            <img @click="subscribe(item, index)" v-if="item.userProductStatus == 2 || item.userProductStatus == null" src="../../assets/xinjiangImage/focus_hover.png" alt="" />
                            <img @click="subscribe(item, index)" v-if="item.userProductStatus == 1" src="../../assets/xinjiangImage/focus.png" alt="" />
                            <el-button round type="primary" size="mini" @click="goDetail(item)">了解详情</el-button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </el-tab-pane>
                <el-tab-pane label="基础产品" name="second">
                  <div>
                    <div class="common-style">
                      <div v-for="(item, index) in basicList">
                        <img v-if="index == 0" src="../../assets/xinjiangImage/credit-report-basic-img.png" alt="">
                        <img v-if="index == 1" src="../../assets/xinjiangImage/credit-report-value-img.png" alt="">
                        <img v-if="index == 2" src="../../assets/xinjiangImage/company-relation-img.png" alt="">
                        <img v-if="index == 3" src="../../assets/xinjiangImage/product-img.png" alt="">
                        <img v-if="index == 4" src="../../assets/xinjiangImage/black-name-list-img.png" alt="">
                        <div class="solution-text">
                          <p>{{ item.productName }}</p>
                          <div class="product-detail" v-html="showHtml(item.productDesc)"></div>
                          <div class="product-operate-btn">
                            <img @click="collect(item, index)" v-if="item.userCollectstatus == 2 || item.userCollectstatus == null" src="../../assets/xinjiangImage/shouCangHover.png" alt="" />
                            <img @click="collect(item, index)" v-if="item.userCollectstatus == 1" src="../../assets/xinjiangImage/shouCangfocus.png" alt="" />
                            <img @click="subscribe(item, index)" v-if="item.userProductStatus == 2 || item.userProductStatus == null" src="../../assets/xinjiangImage/focus_hover.png" alt="" />
                            <img @click="subscribe(item, index)" v-if="item.userProductStatus == 1" src="../../assets/xinjiangImage/focus.png" alt="" />
                            <el-button round type="primary" size="mini" @click="goDetail(item)">了解详情</el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="解决方案" name="third">
                  <div>
                    <div class="common-style">
                      <div v-for="(item, index) in solveList">
                        <img v-if="index == 0" src="../../assets/xinjiangImage/risk-monitor-img.png" alt="">
                        <img v-if="index == 1" src="../../assets/xinjiangImage/finance-img.png" alt="">
                        <img v-if="index == 2" src="../../assets/xinjiangImage/shopping-result-img.png" alt="">
                        <img v-if="index == 3" src="../../assets/xinjiangImage/intelligence-friend-img.png" alt="">
                        <div class="solution-text">
                          <p>{{ item.productName }}</p>
                          <div class="product-detail" v-html="showHtml(item.productDesc)"></div>
                          <div class="product-operate-btn">
                            <img @click="collect(item, index)" v-if="item.userCollectstatus == 2 || item.userCollectstatus == null" src="../../assets/xinjiangImage/shouCangHover.png" alt="" />
                            <img @click="collect(item, index)" v-if="item.userCollectstatus == 1" src="../../assets/xinjiangImage/shouCangfocus.png" alt="" />
                            <img @click="subscribe(item, index)" v-if="item.userProductStatus == 2 || item.userProductStatus == null" src="../../assets/xinjiangImage/focus_hover.png" alt="" />
                            <img @click="subscribe(item, index)" v-if="item.userProductStatus == 1" src="../../assets/xinjiangImage/focus.png" alt="" />
                            <el-button round type="primary" size="mini" @click="goDetail(item)">了解详情</el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="信用信息服务平台" name="fourth">
                  <div>
                    <div class="common-style">
                      <div v-for="(item, index) in informationList">
                        <img v-if="index == 0" src="../../assets/xinjiangImage/agricultrue.png" alt="">
                        <img v-if="index == 1" src="../../assets/xinjiangImage/company-finance-platform-img.png" alt="">
                        <img v-if="index == 2" src="../../assets/xinjiangImage/local-finance-platform-img.png" alt="">
                        <img v-if="index == 3" src="../../assets/xinjiangImage/online-security-platform-img.png" alt="">
                        <div class="solution-text">
                          <p>{{ item.productName }}</p>
                          <div class="product-detail" v-html="showHtml(item.productDesc)"></div>
                          <div class="product-operate-btn">
                            <img @click="collect(item, index)" v-if="item.userCollectstatus == 2 || item.userCollectstatus == null" src="../../assets/xinjiangImage/shouCangHover.png" alt="" />
                            <img @click="collect(item, index)" v-if="item.userCollectstatus == 1" src="../../assets/xinjiangImage/shouCangfocus.png" alt="" />
                            <img @click="subscribe(item, index)" v-if="item.userProductStatus == 2 || item.userProductStatus == null" src="../../assets/xinjiangImage/focus_hover.png" alt="" />
                            <img @click="subscribe(item, index)" v-if="item.userProductStatus == 1" src="../../assets/xinjiangImage/focus.png" alt="" />
                            <el-button round type="primary" size="mini" @click="goDetail(item)">了解详情</el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>

          </div>
        </div>
      </div>

    <!-- <foot></foot> -->
    <dialogLogin @closeDialog="dialogFun" v-if="dialogVisible"></dialogLogin>
  </div>
</template>

<script>
  import { mapState, mapGetters } from "vuex";
  import dialogLogin from "../../components/dialogLogin/dialogLogin";
    import TopHeader from '@/components/header/TopHeader'
  import foot from "@/components/footer/footer";
  import {removeHeaderToken,getHeaderToken,getUserId,removeUserId} from "../../plugins/auth";
  export default {
    name: "ConductList",
    components: {
      TopHeader,
      dialogLogin,
      foot,
    },
    props:{
      userDataShowTop:Boolean,
  },
    data() {
      return {
        activeName: 'first',
        tabIndex: 0,
        headerShow: false,
        productList: [],
        dialogVisible: false,
        userData: {},
        tabBatList: ["全部", "基础产品", "解决方案", "信用信息服务平台"],
        qieHuanIndex: 0,
        basicList: [],
        solveList: [],
        informationList: [],
      };
    },
    computed: {
      ...mapState("user", { token: "token" }),
      ...mapGetters("user", { getToken: "getToken" }),
    },
    created() {
      this.getLocal();

      // this.getProductList();
    },
    activated() {
      this.getLocal();
    },
    mounted() {},
    methods: {
      getLocal() {
        if(getHeaderToken()){
          // console.log(getUserId(),'getUserId()')
          this.headerShow = false
          this.$axios.get('/user/annualById?id='+getUserId()).then(res => {
            this.userData = res.data.data
            // console.log(this.userData,'userData')
            this.getProductList();
        }).catch(() => {
          this.getProductList();
        })
      }else{
        this.getProductList();
          this.userData = {}
          this.headerShow = true
        }
      },
      goUser() {
        this.$router.push("/usercenter");
      },
      goShouYe() {
        this.$router.push("/");
      },
      //产品列表
      getProductList() {
        let data = {
          userId: this.userData.id,
          productStatus: "1",
        };
        this.$axios.post("/product/getProduct", data).then((res) => {
          if (res.data.code == 200) {
            this.productList = res.data.data;
            this.basicList=[];
            this.solveList =[];
            this.informationList=[];
            this.productList.forEach((item, index) => {
              if (item.type == 1) {
                this.basicList.push(item);
              }
            });
            this.productList.forEach((item, index) => {
              if (item.type == 2) {
                this.solveList.push(item);
              }
            });
            this.productList.forEach((item, index) => {
              if (item.type == 3) {
                this.informationList.push(item);
              }
            });
          }
        }).catch((err) => {
          // console.log(err);
        });
      },
      //订阅
      subscribe(item, index) {
        if (this.userData.id) {
          let data = {
            userId: this.userData.id,
            productId: item.id,
          };
          if (item.userProductStatus == 2) {
            this.$axios.post("/userProduct/buyProduct", data).then((res) => {
              if (res.data.code == 200) {
                this.$notify({
                  title: "成功",
                  message: "订阅成功",
                  type: "success",
                });
                this.getProductList();
              }
            });
          }
          if (item.userProductStatus == 1) {
            this.$axios.post("/userProduct/cancelProduct", data).then((res) => {
              if (res.data.code == 200) {
                this.$notify({
                  title: "成功",
                  message: "取消订阅",
                  type: "success",
                });
                this.getProductList();
              }
            });
          }
        } else {
          this.dialogVisible = true;
        }
      },
      //收藏
      collect(item, index) {
        // console.log(item,this.userData,'000')
        if (this.userData.id != null) {
          let data = {
            userId: this.userData.id,
            productId: item.id,
          };
          if (item.userCollectstatus == 2) {
            this.$axios.post("/userProduct/collectProduct", data).then((res) => {
              if (res.data.code == 200) {
                this.$notify({
                  title: "成功",
                  message: "收藏成功",
                  type: "success",
                });
                this.getProductList();
              }
            });
          }
          if (item.userCollectstatus == 1) {
            this.$axios.post("/userProduct/dCollec", data).then((res) => {
              if (res.data.code == 200) {
                this.$notify({
                  title: "成功",
                  message: "取消收藏",
                  type: "success",
                });
                this.getProductList();
              }
            });
          }
        } else {
          this.dialogVisible = true;
        }
      },
      login() {
        this.dialogVisible = true;
      },
      dialogFun(val) {
        this.dialogVisible = val;
        this.getLocal();
        this.getProductList();
      },
      goDetail(item) {
        if (item.productName == "黑名单") {
          this.$router.push("conductDetail");
        } else if (item.productName == "企业基础版信用报告" || item.productName =="企业增值版信用报告") {
          this.$router.push("creditReport");
        } else if (item.productName == "中小企业关联图谱") {
          this.$router.push("relevance");
        } else if (item.productName == "信用评分") {
          this.$router.push("grade");
        } else if (item.productName == "风险监测及预警") {
          this.$router.push("check");
        } else if (item.productName == "科创企业融资解决方案") {
          this.$router.push("solution");
        } else if (item.productName == "零售消费信贷智能决策解决方案") {
          this.$router.push("retail");
        } else if (item.productName == "智能信贷获客系统") {
          this.$router.push("intellect");
        } else if (item.productName == "农村信用信息共享服务平台") {
          this.$router.push("village");
        } else if (item.productName == "中小微企业综合金融服务平台") {
          this.$router.push("banking");
        } else if (item.productName == "地方金融聚信服务平台") {
          this.$router.push("local");
        } else if (item.productName == "线上银担直联服务平台") {
          this.$router.push("online");
        } else {
          let name = item.productName
          this.$router.push({path:`/newproduct/${name}`});
        }
      },
      showHtml(str) {
        // .replace(/<\/?[^>]*>/g, "")     //去标签
        return str.replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
                .replace(/&lt;/g, "<")
                .replace(/&gt;/g, ">")
                .replace(/&quot;/g, '"')
                .replace(/&#39;/g, "'")
                .replace(/&amp;nbsp;/g, "\u3000");
      },
      //退出登录
      handleCommand() {
        this.$confirm("您确认退出登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((res) => {
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          removeHeaderToken();
          removeUserId();
          this.getLocal();
        }).catch((err) => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  ::v-deep .product-center-wrapper {
    position: relative;
    .product-top-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      min-width: 1200px;
      background-image: url(../../assets/xinjiangImage/credit-value.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 200px 0;
    }
    .product-content-wrapper {
      background-color: #F7FAFF;
      padding-top: 350px;
      padding-bottom: 96px;
      .product-content {
        position: relative;
        z-index: 10;
        width: 1200px;
        margin: 0 auto;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        background-color: #F7FAFF;
        .product-type-wrapper {
          .el-tabs__header.is-top {
            background: #ffffff;
            border-radius: 16px;
            margin-bottom: 32px;
            .el-tabs__nav-wrap {
              padding: 34px;
              &::after {
                position: static !important;
              }
              .el-tabs__nav-scroll {
                overflow: visible;
                .el-tabs__active-bar {
                  bottom: -34px;
                }
              }
            }
            .el-tabs__item.is-top {
              font-size: 30px;
              font-family: Source Han Sans CN;
              font-weight: 400;
            }
          }
        }
        .common-style {
          &>div:nth-child(odd) {
            margin-right: 50px;
          }
          &>div {
            display: inline-block;
            width: 575px;
            height: 294px;
            background: #ffffff;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            margin-bottom: 26px;
            img {
              float: left;
              width: 210px;
              height: 100%;
            }
            .solution-text {
              position: relative;
              float: left;
              height: 294px;
              width: 365px;
              box-sizing: border-box;
              padding: 40px 0px 18px 0px;
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #252525;
              &>p {
                text-align: center;
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #0264E7;
                margin-top: 0;
                margin-bottom: 10px;
                padding: 0px 10px;
              }
              .product-detail {
                height: 144px;
                font-size: 18px;
                text-indent: 2em;
                padding: 0px 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                ul,ol{
                  list-style: none;
                  margin: 0;
                  padding: 0;
                  // line-height: 24px;
                  // font-size: 14px;
                  // color: #252525;
                  // &>li {
                  //   margin-bottom: 6px;
                  // }
                }
                *{
                  margin: 0;
                }
              }
              .product-operate-btn {
                margin-top: 12px;
                padding: 0px 30px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                &>img {
                  width: 30px;
                  margin: 0 4px;
                  cursor: pointer;
                }
                .el-button {
                  margin: 0 10px;
                  padding: 7px 25px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
