<template>
    <div class="footer">
        <div style="display: flex;align-items: center;justify-content: space-between">
            <div class="footerText">
                <p>主办单位：新疆征信有限责任公司</p>
                <p>地 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 址：新疆乌鲁木齐市水磨沟区昆仑东街791号金融大厦2#办公楼14楼</p>
                <p>备&nbsp;&nbsp;案&nbsp;&nbsp;号：<a style="color: #9E9E9E;text-decoration: none;">新ICP备2023000537号</a></p>
                <p style="display: flex;">
                    <img src="../../../src/assets/xinjiangImage/gongan.png"/>公安备案号：<a style="color: #9E9E9E;text-decoration: none;">新公网安备 65010502000881号</a>
                </p>

            </div>
            <div class="footerImg">
                <img src="../../../public/static/image/zhengfuzhaocuo.png">
                <img style="width: 60px;height: 60px;margin-left: 20px" src="../../../public/static/image/jiguan.png">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "foot"
    }
</script>

<style scoped lang="less">
    .footer{
        width: 100%;
        //margin-top: 60px;
        padding: 2% 18.8%;
        height: 220px;
        background: #222830;
        box-shadow: inset 0px 4px 0px 0px #0060ff;
        box-sizing: border-box;
        .footerText{
            font-family: MicrosoftYaHei;
            font-size: 14px;
            color: #9E9E9E;
            font-weight: 400;
        }
    }
</style>
